<template>
  <div class="rule-design-page">
    <rule-design></rule-design>
  </div>
</template>

<script>
//
import ruleDesign from "../components/rulesDesign/ruleDesign.vue"
import {getList,addRule,deleteRule,getNodes,getEventsData,getMetadata,saveMetadata,resetRootRuleChain} from "@/api/manager/rules-manager.js"

export default {
  name:'rule-design-page',
  //
  components:{ruleDesign}
}
</script>


<style scoped lang="scss">
.rule-design-page{
  height:100%;
  width:100%;
}
</style>